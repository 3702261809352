.MMP-logo-name {
  max-height: 300px;
  max-width: 70%;
}
.MMP-sample-image {
}

.MMP-general-image {
  max-height: 450px;
  max-width: 80%;
}

.MMP-blog-image {
  max-height: 750px;
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
